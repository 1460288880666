@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  height: fit-content;
}
.ML__keyboard *,
::before,
::after {
  border: black;
}
.disabled-text {
  color: var(--old-grey-400);
}

.dashboard-content-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .my-study-content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    h1 {
      color: var(--gray-10);
      font-family: Pretendard;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2rem;
    }
  }
}

.student-dashboard-wrapper {
  padding: 1.5rem 2.5rem;
  width: 100%;
  height: calc(100dvh - var(--height-global-nav) - var(--height-local-nav));
  overflow-y: auto;
}
.teacher-dashboard-section {
  width: 100%;
  padding: 1.5rem 2.5rem;
}
.footer {
  border-top: 1px solid var(--gray-70);
  background: var(--gray-95);
  width: 100%;
  height: 4rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.375rem;
  position: fixed;
  bottom: 0;
  z-index: 15;
}
.create-form-wrapper {
  display: flex;
  justify-content: center;
  > section {
    width: 31.5rem;
    > h1 {
      font-weight: 700;
      font-size: 24px;
      padding-bottom: 1.875rem;
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      > div {
        height: 2.8rem;
        display: grid;
        grid-template-columns: 2fr 5fr;
        align-items: center;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 32, 41, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .modal-content {
    position: absolute;
    background-color: white;
    border-radius: 0.625rem;
    box-shadow: 0px 8px 24px 0px rgba(25, 32, 41, 0.16);
    z-index: 9999;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ML__keyboard .ML__keyboard--plate > div > div.keyboard-toolbar {
  width: 100% !important;
  font-size: 0.8125rem;
}

.disable-drag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@layer base {
  :root {
    --radius: 0.5rem;
  }
}
