:root {
  --old-red: #ff756e;
  --old-red-20: #ffd6d6;
  --old-red-50: #ffa4a0;
  --old-yellow: #ffd631;
  --old-yellow-50: #ffe57b;
  --old-navy: #205896;
  --old-blue: #1c88ff;
  --old-blue-hover: #2379d8;
  --old-light-blue: #eaf4ff;
  --old-green: #00e676;
  --old-mint: #06d8b2;
  --old-purple: #8f00ff;
  --old-grey-0: #fff;
  --old-grey-100: #f5f5f5;
  --old-grey-200: #eeeeee;
  --old-grey-300: #e0e0e0;
  --old-grey-400: #bdbdbd;
  --old-grey-500: #9e9e9e;
  --old-grey-600: #757575;
  --old-grey-700: #616161;
  --old-grey-800: #424242;

  --gray-00: #000000;
  --gray-10: #192029;
  --gray-20: #303a47;
  --gray-30: #495565;
  --gray-40: #667282;
  --gray-50: #8592a0;
  --gray-60: #a8b2be;
  --gray-70: #ced5dc;
  --gray-80: #e2e7eb;
  --gray-90: #edf0f2;
  --gray-95: #f7f9fa;
  --gray-100: #ffffff;

  --blue-10: #00186d;
  --blue-20: #022db0;
  --blue-30: #0842dc;
  --blue-40: #1255ef;
  --blue-50: #226afa;
  --blue-60: #3e7efe;
  --blue-70: #6196ff;
  --blue-80: #a2c2ff;
  --blue-90: #d2e2ff;
  --blue-95: #ebf1ff;
  --blue-98: #f5f9ff;

  --red-10: #a61b28;
  --red-20: #c42031;
  --red-30: #dd293a;
  --red-40: #ed3546;
  --red-50: #f64857;
  --red-60: #fb6872;
  --red-70: #fc8289;
  --red-80: #fd9ca1;
  --red-90: #fec1c3;
  --red-95: #ffe6e6;
  --red-98: #fff5f5;

  --orange-10: #ad3e07;
  --orange-20: #bd4809;
  --orange-30: #cc5112;
  --orange-40: #e7601d;
  --orange-50: #fa7132;
  --orange-60: #ff8548;
  --orange-70: #ffa067;
  --orange-80: #ffc08f;
  --orange-90: #ffdebc;
  --orange-95: #ffead4;
  --orange-98: #fff5eb;

  --yellow-10: #cc6d00;
  --yellow-20: #e58005;
  --yellow-30: #ec8e13;
  --yellow-40: #f4a318;
  --yellow-50: #fab619;
  --yellow-60: #fec642;
  --yellow-70: #fed661;
  --yellow-80: #ffe085;
  --yellow-90: #ffebb2;
  --yellow-95: #fcf2d4;
  --yellow-98: #fffaeb;

  --green-10: #027545;
  --green-20: #03844e;
  --green-30: #069358;
  --green-40: #0aa263;
  --green-50: #00b268;
  --green-60: #22bf7e;
  --green-70: #39cc8f;
  --green-80: #77d9b0;
  --green-90: #afedd3;
  --green-95: #e1f5ed;
  --green-98: #f5faf8;

  --cyan-10: #076666;
  --cyan-20: #097676;
  --cyan-30: #0c8686;
  --cyan-40: #119696;
  --cyan-50: #19a7a7;
  --cyan-60: #28b7b7;
  --cyan-70: #3fc7c7;
  --cyan-80: #65d7d7;
  --cyan-90: #a9ebeb;
  --cyan-95: #dcf5f5;
  --cyan-98: #f2fcfc;

  --purple-10: #551e6b;
  --purple-20: #6e228c;
  --purple-30: #7f22a3;
  --purple-40: #9029b9;
  --purple-50: #a336cf;
  --purple-60: #b650de;
  --purple-70: #c573e5;
  --purple-80: #dfa6f5;
  --purple-90: #edcbfb;
  --purple-95: #f9ebff;
  --purple-98: #fbf7fc;

  --Hover: rgba(21, 32, 43, 0.08);
  --Active: rgba(25, 32, 41, 0.12);

  --height-global-nav: 3.5rem;
  --height-mobile-nav: 3.5rem;
  --height-local-nav: 3rem;
  --height-bottom-nav: 4rem;
  --height-material-info-box: 3.5rem;
  --height-footer: 4rem;
  --height-problem-solving-header: 3rem;
  --height-problem-solving-footer: 3.5rem;
  --height-study-status-tab: 3.5rem;
  --height-side-bar: 12.5rem;
  --width-open-side-bar: 12.5rem;
  --width-close-side-bar: 4rem;
}

@mixin boxShadow {
  box-shadow: 0px 2px 10px rgba(101, 115, 128, 0.15);
}
